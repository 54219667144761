import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { Flex, Box, Text, Image } from "rebass"
import { useForm } from "react-hook-form"
import qs from "qs"

import { AccountContext } from "../../context/account"
import LoginLayout from "../../components/login-layout"
import SEO from "../../components/seo"
import InputField from "../../components/input"
import Button from "../../components/button"
import Spinner from "../../components/spinner"
import Graphic from "../../assets/login-graphic.png"

import MedusaClient from "../../services/api"

const IndexPage = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const account = useContext(AccountContext)
  const { register, handleSubmit } = useForm()

  useEffect(() => {
    const [, ...search] = location.search
    const { code, state, cli_tok, req_tok } = qs.parse(search)

    MedusaClient.auth
      .provider("github", {
        state,
        code,
      })
      .then(async ({ data }) => {
        if (data.has_account) {
          await MedusaClient.cliAuth.authenticate(cli_tok, req_tok)
          navigate("/cli-login/done")
        } else {
          navigate(
            `/signup/github?${qs.stringify({
              ...data.details,
              cli_tok,
              req_tok,
              next: "cli",
              state,
            })}`
          )
        }
      })
  }, [])

  return (
    <LoginLayout pt={3}>
      <SEO title="GitHub" />
      <Text mb={2} fontWeight="bold" fontSize={4}>
        Signing in
      </Text>
      {loading && (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      )}
    </LoginLayout>
  )
}

export default IndexPage
